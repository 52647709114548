import React from 'react'
import ProcessSection from './ProcessSection'

const ProcessSectionPage = () => {
  return (
    <div className='how-it-works-page'>
      <ProcessSection />
    </div>
  )
}

export default ProcessSectionPage
